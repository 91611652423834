import React from "react";
import "animate.css";
import { Link } from "react-router-dom";

const data = require("./data.json");

function Stores() {
  return (
    <div className="row">
      {data.map((item, index) => (
        <div className="col-lg-6 p-0" key={item.id}>
          <div
            className={`store-item animate__animated ${
              index < 2
                ? index % 2 === 0
                  ? "animate__fadeInRight"
                  : "animate__fadeInLeft"
                : index % 2 === 0
                ? "animate__fadeInRight animate__delay-0-3s"
                : "animate__fadeInLeft animate__delay-0-3s"
            }`}
          >
            <Link to="/uc">
              <div
                className="store-img-bg"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>
              <h3 className="title">{item.title}</h3>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Stores;
