import React from "react";
import { Link } from "react-router-dom";
import "animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import logo from "../../assets/images/logo.png";

function uc() {
  return (
    <div className="uc-section justify-content-center d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="justify-content-center d-flex mb-5">
              <img
                src={logo}
                alt="logo"
                className="img-fluid w-25 animate__animated animate__fadeIn"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="uc-content-area text-center mb-5 animate__animated animate__fadeIn">
              <h1 className="mb-4">قريباً</h1>
              <h4>نحن نعمل على الإصلاحات والتحسينات، وسيتم العودة قريباً.</h4>
            </div>
          </div>
          <div className="col-12">
            <Link
              to="/"
              className="text-center justify-content-center d-flex animate__animated animate__fadeIn"
            >
              <FontAwesomeIcon icon={faArrowRight} className="ms-1 mt-1" /> العودة إلى الصفحة الرئيسية
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default uc;
