import React, { useEffect } from "react";
import "animate.css";
import Stores from "./components/Stores/Stores";
import logo from "./assets/images/logo.png";

function App() {
  useEffect(() => {
    if (window.innerWidth < 991) {
      setTimeout(() => {
        const storesArea = document.querySelector(".info-area");
        const storesAreaHeight = storesArea.offsetHeight;
        document.querySelector(".stores-area").style.height =
          window.innerHeight - storesAreaHeight + "px";
      }, 2000);
    }
  }, []);

  return (
    <section className="store-listing">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-4 p-0 align-self-center">
            <div className="info-area justify-content-center d-flex">
              <img
                src={logo}
                alt="logo"
                className="img-fluid w-75 align-self-center py-5 store-item animate__animated animate__fadeIn"
              />
            </div>
          </div>
          <div className="col-lg-8 p-0">
            <div className="stores-area">
              <Stores />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
